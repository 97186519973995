import React, { createContext, useReducer, useEffect, ReactNode } from 'react';
import AuthReducer, { ActionTypes, State } from './AuthReducer';
import User from 'src/models/entries/User';
import { getUserData, LocalStorageKeys, removeUserDate, saveUser } from 'src/utils/axios/StorageManager';

interface Props {
  storageKey: LocalStorageKeys;
  children: ReactNode;
}

const initialState: State = {
  user: null,
  isAuthenticated: false,
};

export const AuthContext = createContext<{
  user: User| null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
}>({
  ...initialState,
  login: () => {},
  logout: () => {},
});

const AuthProvider: React.FC<Props> = ({ children, storageKey }) => {
  const [state, dispatch] = useReducer(AuthReducer, {
    user: getUserData(storageKey),
    isAuthenticated: getUserData(storageKey) ? true : false
  });

  // useEffect(() => {
  //   const user = getUserData(storageKey)
  //   if (user) {
  //     dispatch({ type: ActionTypes.LOGIN, payload: user });
  //   }
  // }, [storageKey]);

  const login = (user: User) => {
    saveUser(user, storageKey);
    dispatch({ type: ActionTypes.LOGIN, payload: user });
  };

  const logout = () => {
    removeUserDate(storageKey);
    dispatch({ type: ActionTypes.LOGOUT });
  };

  return <AuthContext.Provider value={{ ...state, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
