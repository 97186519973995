import axios, { AxiosRequestConfig } from "axios";
import { allLangs, defaultLang } from "src/config";
import Device from "src/models/entries/Device";
import { sleep } from "../utils";
import { dateTransformer } from "./AxiosTransformers";
import { getAccessToken, getRefershToken, LocalStorageKeys, updateTokens } from "./StorageManager";

const langStorage = localStorage.getItem('i18nextLng');

const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;
const AdminClient = axios.create({
  withCredentials: true,
  transformRequest: [ dateTransformer ].concat(axios.defaults.transformRequest ?? [])
});

let logoutAdmin: () => void;


declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean;
    _auth?: boolean;
  }
}



AdminClient.defaults.baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:5500/v1";

const sleepTime = process.env.REACT_APP_LOADING_TIME_OUT ?? '0';
const environment = process.env.REACT_APP_ENVIRONMENT;

const adminInterceptorConfig =  async (config: AxiosRequestConfig) => {
  const accessToken = getAccessToken(LocalStorageKeys.ADMIN);
  return axiosAuthConfiguration(config, accessToken);
}



const axiosAuthConfiguration = (config: AxiosRequestConfig, accessToken: string | null) => {
  config.headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'accept-language': localStorage.getItem('i18nextLng') ?? defaultLang.value,
    'language': localStorage.getItem('i18nextLng') ?? defaultLang.value,
    'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  config.withCredentials = true
  config._auth = config._auth !== false
  return config;
}


AdminClient.interceptors.request.use(
  adminInterceptorConfig,
    error => {
      Promise.reject(error.request.response)
});

  

AdminClient.interceptors.response.use(async (response) => {
    if (environment === 'development') {
      await sleep(parseInt(sleepTime));
    }
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest._auth) {
      logoutAdmin();
    }
    return Promise.reject(error.request.response);
});


const listenForAdminState = (callBack:()=>void) => {
  logoutAdmin = callBack;
}


export {AdminClient, listenForAdminState};
