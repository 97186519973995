import Router from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Provider } from 'react-redux';

import 'identity-admin-ui/lib/cjs/index.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { store } from './store';
import Cookies from 'js-cookie';
import {
  ApiContextProvider,
  AppConfigurationsContextProvider,
  CredentialsContextProvider,
  DialogContextProvider,
  PathsContextProvider,
  ResourcesContextProvider,
  SnackAlertProvider,
} from 'identity-admin-ui';
import { adminPath, vendorUserPath } from './controllers/ApiPaths';
import { useLocation } from 'react-router-dom';
import useLocales from './hooks/useLocales';
import { ADMIN_ROOT, VendorUser_ROOT } from './routes/paths';
import { AWS, GoogleMapsApiKey } from './config';
import { LocalStorageKeys } from './utils/axios/StorageManager';
import AuthContextProvider from './contexts/AuthContext';
import { useAuth } from './hooks/useAuth';

window.addEventListener('beforeunload', (event) => {
  const tabCounter = parseInt(Cookies.get('tabCounter') ?? '');
  if (tabCounter) {
    Cookies.set('tabCounter', `${tabCounter - 1}`);
  }
});

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <SnackAlertProvider>
            <AuthContextProvider storageKey={LocalStorageKeys.ADMIN}>
              <Dashboard />
            </AuthContextProvider>
          </SnackAlertProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

function Dashboard() {
  const { logout } = useAuth();

  return (
    <ApiContextProvider
      language={'en'}
      baseUrl={process.env.REACT_APP_BASE_URL ?? 'http://localhost:5500/v1'}
      onLogout={logout}
      dashbaordUrlPrefix={adminPath}
    >
      <ResourcesContextProvider
        resourcesUrl={'resources'}
        laodingLogo="/assets/illustrations/logo.jpeg"
        logoSxProps={{ width: 40, height: 40 }}
      >
        <DialogContextProvider>
          <PathsContextProvider root={ADMIN_ROOT} home={'project'}>
            <AppConfigurationsContextProvider>
              <CredentialsContextProvider
                identityPoolId={AWS.COGNITO_IDENTITY_POOL_ID ?? ''}
                region={AWS.REGION ?? ''}
                userPoolId={AWS.USER_POOLS_ID ?? ''}
                userPoolWebClientId={AWS.USER_POOLS_WEB_CLIENT_ID ?? ''}
                bucket={AWS.BUCKET_NAME ?? ''}
                googleApiKey={GoogleMapsApiKey ?? ''}
                bucketUrl={AWS.BUCKET_URL ?? ''}
              >
                <ProgressBarStyle />
                <ScrollToTop />
                <Provider store={store}>
                  <Router />
                </Provider>
              </CredentialsContextProvider>
            </AppConfigurationsContextProvider>
          </PathsContextProvider>
        </DialogContextProvider>
      </ResourcesContextProvider>
    </ApiContextProvider>
  );
}
