import { noCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Paper,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_

import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { fetchNotifications, getNotifications } from 'src/store/features/utils/NotificationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { ActionNames } from 'src/helpers/EnumHelper';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { useNavigate } from 'react-router';
import { ADMIN_PATH } from 'src/routes/paths';
import { LocalesHelper } from 'src/helpers/Localization/LocalesHelpers';
import { ArabicNotification, EnglishNotification } from 'src/helpers/Localization/NotificationsLocalization';
import { AdminClient } from 'src/utils/axios/Axiosinterceptor';

// ----------------------------------------------------------------------

export interface IDashboardNotifications {
  text: string;
  createdAt: Date;
  clickable: boolean;
  actionType?: ActionNames;
  path?: string;
  recordId?: string;
  isUnRead: boolean;
  notificationId?: string;
}

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const { notifications, isLoading } = useAppSelector(getNotifications);
  const isEnglish = LocalesHelper.isEnglish();
  const texts = isEnglish ? EnglishNotification : ArabicNotification;

  const [notificationsState, setNotifications] = useState<IDashboardNotifications[] | undefined>(notifications);
  const [showAll, setShowAll] = useState<boolean>(false);

  const totalUnRead = notificationsState?.filter((item) => item.isUnRead === true).length;
  const navigate = useNavigate();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    const response = AdminClient.post('/admin/notifications/markAllAsRead');
    setNotifications(
      notificationsState?.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  useEffect(() => {
    setNotifications(notificationsState);
  }, [notifications]);

  const onClickNotification = (clickedNotification: IDashboardNotifications) => {
    setOpenPopover(null);
    if (clickedNotification.isUnRead) {
      const response = AdminClient.patch(`/admin/adminNotifications/${clickedNotification.notificationId}`, { isRead: true });
    }
    setNotifications(
      notificationsState?.map((notification) => {
        if (notification.recordId === clickedNotification.recordId) {
          return {
            ...notification,
            isUnRead: false,
          };
        }
        return {
          ...notification,
        };
      })
    );
    navigate(ADMIN_PATH[clickedNotification.path!].show(clickedNotification.recordId));
  };
  const list = showAll ? notificationsState : notificationsState?.slice(0, 4);

  return (
    <>
      <IconButtonAnimate color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{texts.notifications}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${texts.youHave} ${totalUnRead ?? 0} ${texts.unreadMessages}`}
            </Typography>
          </Box>

          {totalUnRead && totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Paper style={{ minHeight: 200, maxHeight: 400, overflow: 'auto' }}>
          <List disablePadding>
            {list?.map((notification) => (
              <NotificationItem key={notification.text} notification={notification} onClickNotification={onClickNotification} />
            ))}
          </List>
        </Paper>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {(notificationsState?.length ?? 0) > 4 && (
          <Box sx={{ p: 1 }}>
            <Button fullWidth disableRipple onClick={() => setShowAll(!showAll)}>
              {showAll ? texts.showLess : texts.showMore}
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
};

function NotificationItem({
  notification,
  onClickNotification,
}: {
  notification: IDashboardNotifications;
  onClickNotification: (clickedNotification: IDashboardNotifications) => void;
}) {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => onClickNotification(notification)}
    >
      <ListItemText
        disableTypography
        primary={notification.text}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------
