import { Navigate, useNavigate } from 'react-router-dom';
import Login, { UserTypes } from 'src/pages/auth/Login';
import customs from './CustomPages';
import { ApiContext, IdentityEdit, IdentityList, IdentityShow, PathsContext, ResourcesContext } from 'identity-admin-ui';
import { useContext, useEffect, useState } from 'react';
import IResource from 'src/models/entries/Resource';
import DashboardLayout from 'src/layouts/dashboard';
import { useAuth } from 'src/hooks/useAuth';
import { ADMIN_PATH } from './paths';
import { adminPath } from 'src/controllers/ApiPaths';

export type sizeType = 'small' | 'medium' | undefined;

export var SIZE: sizeType = 'small';

export default function AdminRouter() {
  const { resources, getResources, clearResources } = useContext(ResourcesContext);
  const { paths, root, home } = useContext(PathsContext);
  const { statusCode } = useContext(ApiContext);
  const [children, setChildren] = useState<any>([]);
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!resources && isAuthenticated && user) {
      getResources();
    }
  }, [statusCode, resources, user, isAuthenticated, getResources, navigate, root]);

  useEffect(() => {
    if (resources && paths && isAuthenticated && user) {
      const updatedChildren: (
        | {
            path: string;
            children: (
              | { element: JSX.Element; index: boolean; path?: undefined }
              | { path: string; element: JSX.Element; index?: undefined }
            )[];
          }
        | { path: string; children: { path: string; element: JSX.Element }[] }
      )[] = [];
      Object.keys(paths).forEach((key) => {
        ADMIN_PATH[key] = paths[key];
      });

      updatedChildren.push(...customs);

      for (const resource in resources) {
        if (resource === 'modelParents' || resource === 'appConfigurations') {
          continue;
        }

        const resourceFile = resources[resource] as IResource;
        const modelPath = resourceFile.properties.path;
        const { modelName } = resourceFile.properties;

        const modelData = {
          path: modelPath,
          children: [
            { element: <Navigate to={paths[resource].list} replace />, index: true },
            {
              path: 'list',
              element: (
                <IdentityList
                  apiRoute={`${adminPath}/${modelPath}`}
                  key={`${modelPath}.list`}
                  path={resource}
                  modelName={modelName}
                />
              ),
            },
            {
              path: 'new',
              element: <IdentityEdit modelRoute={`${modelPath}`} path={resource} isEdit={false} />,
            },
            {
              path: ':id/edit',
              element: <IdentityEdit modelRoute={`${modelPath}`} path={resource} isEdit={true} />,
            },
            {
              path: ':id/show',
              element: <IdentityShow path={resource} key={`${modelPath}.show`} modelRoute={modelPath} />,
            },
          ],
        };

        updatedChildren.push(modelData);
      }
      setChildren((prev: []) => updatedChildren);
    }
    if (resources && !isAuthenticated && !user) {
      clearResources();
    }
  }, [resources, paths, root, user, isAuthenticated, clearResources]);

  return {
    path: root,
    children: [
      {
        path: 'login',
        element:
          resources && user && isAuthenticated ? (
            <Navigate to={`${paths[home].list}`} />
          ) : (
            <Login userType={UserTypes.EVENT_CREATOR} />
          ),
      },
      {
        path: root,
        element:
          resources && isAuthenticated && user ? (
            <DashboardLayout userType={UserTypes.EVENT_CREATOR} />
          ) : (
            <Navigate to={ADMIN_PATH.auth.login} />
          ),
        children: [
          ...children,
          {
            path: '',
            element:
              resources && isAuthenticated && user ? (
                <Navigate to={`${paths[home].list}`} />
              ) : (
                <Navigate to={ADMIN_PATH.auth.login} />
              ),
          },
        ],
      },
      {
        path: root,
        element:
          resources && isAuthenticated && user ? (
            <DashboardLayout userType={UserTypes.EVENT_CREATOR} />
          ) : (
            <Navigate to={ADMIN_PATH.auth.login} />
          ),
        children: [
          ...children,
          {
            path: '',
            element:
              resources && isAuthenticated && user ? (
                <Navigate to={`${paths[home].list}`} />
              ) : (
                <Navigate to={ADMIN_PATH.auth.login} />
              ),
          },
        ],
      },
    ],
  };
}
