/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RequestState, useApi, useSnackAlert } from 'identity-admin-ui';
import { useNavigate } from 'react-router-dom';
import { FormProvider, RHFLazySelect, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { Option } from 'src/components/hook-form/RHFLazySelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';
import CopyButton from 'src/components/CopyButton';

export default function ShowCroppedVideo({ record }: any) {
  const { requestState: updateAnnotationState, patch: updateVideoAnnotation } = useApi();

  const video = useRef<HTMLVideoElement>(null);
  const [frameRate, setFrameRate] = useState(30);
  const [currentFrame, setCurrentFrame] = useState(0);
  const { setSuccessAlert } = useSnackAlert();
  const navigate = useNavigate();
  const { requestState: actionsState, get: fetchActions } = useApi<{ records: Option[] }>();
  const methods = useForm<any>({
    resolver: yupResolver(
      object({
        annotations: array().of(
          object({
            startFrame: number()
              .required()
              .min(0)
              .typeError('Please select a valid value')
              .test('is-less-than-endFrame', 'Start frame cannot be greater than end frame', function (value) {
                const { endFrame } = this.parent;
                if (value === null || value === undefined) {
                  return false;
                }
                return value <= endFrame;
              }),
            endFrame: number()
              .required()
              .min(0)
              .typeError('Please select a valid value')
              .test('is-in-limit', "End frame can't exceed video length", (endFrame?: number) => {
                if (endFrame && video.current && video.current.duration && video.current.duration * frameRate < endFrame) {
                  return false;
                }
                return true;
              })
              .test('is-greater-than-startFrame', 'End frame cannot be less than start frame', function (value) {
                const { startFrame } = this.parent;
                if (!value) {
                  return false;
                }
                return value >= startFrame;
              }),
          })
        ),
      })
    ),
    defaultValues: {
      annotations: record.annotations ?? [
        {
          startFrame: undefined,
          endFrame: undefined,
          action: '',
          score: 1,
        },
      ],
    },
  });

  const { control, handleSubmit } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'annotations',
  });

  const onSubmit = (data: { annotations: { startFrame: number; endFrame: number; action: string; score: number }[] }) => {
    updateVideoAnnotation({
      url: `tasks/${record._id}`,
      body: {
        annotations: data.annotations.map((annotation) => ({
          startFrame: +annotation.startFrame,
          endFrame: +annotation.endFrame,
          action: annotation.action,
          score: +annotation.score,
        })),
      },
      onSuccess: (data) => {
        setSuccessAlert('Annotaion created successfully');
        navigate(`/tasks/${data.record.document._id}/show`);
      },
    });
  };
  const removeRange = (index: number) => {
    remove(index);
  };
  const addRange = () => {
    append({
      startFrame: undefined,
      endFrame: undefined,
      action: '',
    });
  };

  //   Seek to the previous frame
  const seekToPrevFrame = () => {
    if (video.current) {
      video.current?.pause();
      const currentTime = video.current?.currentTime;
      const newTime = Math.max(currentTime - 1 / frameRate, 0);
      video.current.currentTime = newTime;
      updateFrameCounter();
    }
  };

  //   Seek to the next frame
  const seekToNextFrame = () => {
    if (video.current) {
      video.current.pause();
      const { currentTime } = video.current;
      const newTime = Math.min(currentTime + 1 / frameRate, video.current.duration);
      video.current.currentTime = newTime;
      updateFrameCounter();
    }
  };

  const updateFrameCounter = () => {
    if (video.current) {
      const currentFrame = Math.floor(video.current.currentTime * frameRate);
      setCurrentFrame(currentFrame);
    }
  };

  useEffect(() => {
    const videoElement = video.current;
    if (videoElement) {
      const handleTimeUpdate = () => {
        const currentFrame = Math.floor(videoElement.currentTime * frameRate);
        setCurrentFrame(currentFrame);
      };

      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [frameRate]);

  return (
    <Stack alignItems={'center'} spacing={4}>
      <Stack direction={'row'} spacing={1}>
        <video
          src={record.croppedVideo}
          width={'auto'}
          height={360}
          controls={true}
          className="video-js"
          preload="auto"
          ref={video}
        />
        <video src={record.originalVideo} width={'auto'} height={360} controls={true} className="video-js" preload="auto" />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        <IconButton onClick={seekToPrevFrame}>
          <ArrowBackIosIcon />
        </IconButton>

        <Typography>
          Current Frame: <span>{currentFrame}</span>
        </Typography>

        <IconButton onClick={seekToNextFrame}>
          <ArrowForwardIosIcon />
        </IconButton>
        <CopyButton textToCopy={`${currentFrame}`} />
      </Stack>
      <Box width={'100%'}>
        <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
          <Stack spacing={4} alignItems="center" width={'100%'}>
            <Stack id="annotations" spacing={2} width={'100%'} justifyContent={'space-evenly'}>
              {fields.map((field: any, index: number) => (
                <Stack
                  key={field.id}
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  width={'100%'}
                  justifyContent={'space-evenly'}
                >
                  <RHFTextField
                    type="number"
                    size="small"
                    name={`annotations.${index}.startFrame`}
                    inputProps={{
                      min: '0',
                    }}
                    required
                    className="border rounded-md"
                    label="Start Frame:"
                    fullWidth
                  />
                  <RHFTextField
                    type="number"
                    size="small"
                    name={`annotations.${index}.endFrame`}
                    inputProps={{
                      min: '0',
                    }}
                    required
                    className="border rounded-md"
                    label="End Frame:"
                    fullWidth
                  />
                  <RHFLazySelect
                    options={actionsState?.data?.records ?? []}
                    onOpenChanged={() => {
                      if (actionsState.state === RequestState.IDLE) {
                        fetchActions({
                          url: 'labels',
                        });
                      }
                    }}
                    loading={actionsState.state === RequestState.LOADING}
                    type="text"
                    size="small"
                    name={`annotations.${index}.action`}
                    required
                    className="border rounded-md"
                    label="Action:"
                    fullWidth
                  />
                  <RHFSelect
                    name={`annotations.${index}.score`}
                    label="Score"
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    size="small"
                    inputProps={{
                      min: '0',
                      max: '5',
                    }}
                    fullWidth
                  >
                    {[1, 2, 3, 4, 5].map((number: number) => (
                      <MenuItem key={`annotations${number}`} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  {fields.length > 1 && (
                    <IconButton onClick={() => removeRange(index)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 text-red-600"
                        width={24}
                        height={24}
                        color="red"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </IconButton>
                  )}
                </Stack>
              ))}
            </Stack>
            <Button
              id="addRange"
              onClick={addRange}
              variant="outlined"
              sx={{
                alignSelf: 'start',
              }}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={24}
                  height={24}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              }
            >
              Add Action
            </Button>
            <LoadingButton
              type="submit"
              sx={{ alignSelf: 'end' }}
              variant="contained"
              loading={updateAnnotationState.state === RequestState.LOADING}
            >
              Submit
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </Stack>
  );
}
