import SettingsDrawer from './drawer';
//
import ThemeContrast from './ThemeContrast';
import ThemeRtlLayout from './ThemeRtlLayout';
import ThemeColorPresets from './ThemeColorPresets';
import ThemeLocalization from './ThemeLocalization';
import { Suspense } from 'react';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
    // <ThemeColorPresets>
    //   <ThemeContrast>
    <Suspense fallback={<></>}>
        <ThemeLocalization>
          <ThemeRtlLayout>
            {children}
            {/* <SettingsDrawer /> */}
          </ThemeRtlLayout>
        </ThemeLocalization>
        </Suspense>
    //   </ThemeContrast>
    // </ThemeColorPresets>
  );
}
