import { configureStore } from '@reduxjs/toolkit';
import adminAuthReducer from './features/auth/AdminAuthSlice';
import vendorUserAuthReducer from './features/auth/VendorUserAuthSlice';
import notifictionReducer from './features/utils/NotificationSlice'

export const store = configureStore({
    reducer: {
      auth: adminAuthReducer,
      vendorUserAuth: vendorUserAuthReducer,
      notifications: notifictionReducer,
    },
  })
  
  export type RootState = ReturnType<typeof store.getState>
  export type AppDispatch = typeof store.dispatch
  
