import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { RequestState, useApi, useSnackAlert } from 'identity-admin-ui';

import { useNavigate } from 'react-router';
import ImageWithBoxes from 'src/components/ML/ImageWithBoxes';
import AthleteTable from 'src/sections/videoSigment/AtheleteTable';
import TimeLogTable from 'src/sections/videoSigment/TImeLogTable';

const boxes = [
  { x1: 50, y1: 50, x2: 150, y2: 150 },
  { x1: 200, y1: 100, x2: 300, y2: 200 },
];

export default function ShowSegmentVideo(props: any) {
  const { record } = props.record;
  const { requestState: exportVideoState, post: cropVideo } = useApi<{ video: any }>();
  const { setSuccessAlert } = useSnackAlert();

  const navigate = useNavigate();

  const runVideoCropper = (type: string) => {
    cropVideo({
      url: 'tasks/all',
      body: {
        segmentId: record._id,
        type: type,
      },
      onSuccess(data) {
        setSuccessAlert('Video cropped successfully');
        navigate(`/tasks/list`);
      },
    });
  };

  return (
    <Stack alignItems={'center'} spacing={2}>
      <Card>
        <CardHeader
          title={
            <Typography alignSelf={'center'}>
              Video Segment starting from {record.startTime}sec to {record.endTime}sec
            </Typography>
          }
        />
        <CardContent>
          <video src={record.segmentPath} width={640} height={360} controls={true} />
        </CardContent>
      </Card>

      {record.isTracked && (
        <Card>
          <CardHeader title={<Typography alignSelf={'center'}>Video Segment with athelte tracking.</Typography>} />
          <CardContent>
            <video src={record.trackedPath} width={640} height={360} controls={true} />
          </CardContent>
        </Card>
      )}
      <Card sx={{ width: '100%' }}>
        <CardHeader title="Time Loging" />
        <CardContent>
          <TimeLogTable log={record.takenTime} />
        </CardContent>
      </Card>
      {record.isTracked && (
        <Card sx={{ width: '100%' }}>
          <CardHeader
            title={
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography>Tracked and detected athelets</Typography>
                <Stack direction={'row'} spacing={2}>
                  <LoadingButton
                    variant="contained"
                    loading={exportVideoState.state === RequestState.LOADING}
                    onClick={() => runVideoCropper('Train')}
                  >
                    Generate all tasks
                  </LoadingButton>
                </Stack>
              </Stack>
            }
          />
          <CardContent>
            <AthleteTable athelets={record.trackedBoxes} segmentId={record._id} />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
