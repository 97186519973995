import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useApi, RequestState, useSnackAlert } from 'identity-admin-ui';
import { useNavigate } from 'react-router';

export enum TaskStatus {
  PENDING = 'pending',
  INVALID = 'invalid',
  COMPLETED = 'completed',
}

export default function TaskActions(props: any) {
  const task = props.record?.record ?? props.record;
  const { requestState: athelteTrackingState, patch: updateTask } = useApi<{ trackedPath?: string }>();
  const { setSuccessAlert } = useSnackAlert();
  const navigate = useNavigate();
  const updateStatues = (status: string) => {
    updateTask({
      url: `tasks/${task._id}`,
      body: { status },
      onSuccess(data) {
        setSuccessAlert('Annotaion created successfully');
        navigate(`/tasks/list`);
      },
    });
  };

  if (task.status !== TaskStatus.PENDING) {
    return <></>;
  }

  return (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        variant="outlined"
        sx={{ alignSelf: 'end' }}
        onClick={() => updateStatues('invalid')}
        loading={athelteTrackingState.state === RequestState.LOADING}
        color="secondary"
      >
        Mark as invalid
      </LoadingButton>
      <LoadingButton
        variant="outlined"
        sx={{ alignSelf: 'end' }}
        onClick={() => updateStatues('completed')}
        loading={athelteTrackingState.state === RequestState.LOADING}
      >
        Mark as completed
      </LoadingButton>
    </Stack>
  );
}
