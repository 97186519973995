import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { login as adminLogin, selectRequestState as adminState } from 'src/store/features/auth/AdminAuthSlice';
import {
  login as vendorUserLogin,
  selectVendorUserRequestState as vendorUserState,
} from 'src/store/features/auth/VendorUserAuthSlice';
import RequestState from 'src/models/requests/RequestState';
import { LocalesHelper } from 'src/helpers/Localization/LocalesHelpers';
import { UserTypes } from 'src/pages/auth/Login';
import generateUUID from 'src/utils/UUIDGenerator';
import { useApi, usePaths } from 'identity-admin-ui';
import { useAuth } from 'src/hooks/useAuth';
import User from 'src/models/entries/User';

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
  remember: boolean;
};

export default function LoginForm(props: any) {
  const { userType } = props;
  const isVendorUser = userType === UserTypes.VENDOR_USER;
  const [showPassword, setShowPassword] = useState(false);
  const { status, error } = useAppSelector(isVendorUser ? vendorUserState : adminState);
  const isEnglish: boolean = LocalesHelper.isEnglish();
  const { requestState: loginState, post: login } = useApi<{ user: User }>();
  const { login: saveUserData } = useAuth();
  const { root } = usePaths();
  
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitted },
  } = methods;

  const onSubmit = (data: FormValuesProps) => {
    login({
      url: 'auth/login',
      axiosRequestConfig: {
        _auth: false,
      },
      body: {
        user: {
          email: data.email,
          password: data.password,
          remember: data.remember,
          slug: root.split('/')[2],
        },
        device: {
          deviceId: generateUUID(data.email),
          os: 'WEB',
        },
      },
    });
  };

  useEffect(() => {
    if (loginState.state === RequestState.SUCCEEDED && loginState.data && loginState.data.user) {
      saveUserData(loginState.data.user);
    }
  }, [loginState]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {isSubmitted && status === RequestState.FAILED && <Alert severity="error">{error.message}</Alert>}

        <RHFTextField name="email" label={isEnglish ? 'Email address' : 'البريد الإلكتروني'} />

        <RHFTextField
          name="password"
          label={isEnglish ? 'Password' : 'كلمة المرور'}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={isEnglish ? 'Remember me' : 'تذكرني'} />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={status === RequestState.LOADING}
        sx={{ my: 1 }}
      >
        {isEnglish ? 'Login' : 'تسجيل الدخول'}
      </LoadingButton>
    </FormProvider>
  );
}
