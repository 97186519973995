import { useFormContext, Controller, Control, FieldValues } from 'react-hook-form';
import { Autocomplete, Avatar, Checkbox, CircularProgress, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type IProps = {
  name: string;
  options?: Option[];
  loading: boolean;
  multiple?: boolean;
  onOptionChange?: (value: Option | Option[] | null) => void;
  onOpenChanged: (isOpend: boolean) => void;
  control?: Control<FieldValues, any>;
};

type Props = IProps & TextFieldProps;

export interface Option {
  _id: string;
  title?: string;
  name?: string;
  image?: string;
}

export default function RHFLazySelect({ name, options, loading, multiple, onOptionChange, onOpenChanged, ...other }: Props) {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);

  const handleOpenChangedState = (isOpend: boolean) => {
    onOpenChanged(isOpend);
    setOpen(isOpend);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={multiple}
          fullWidth={other.fullWidth}
          sx={other.sx}
          {...field}
          disableCloseOnSelect={multiple}
          id={name}
          size="medium"
          open={open}
          disabled={other.disabled}
          onOpen={() => handleOpenChangedState(true)}
          onClose={() => {
            handleOpenChangedState(false);
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                {multiple && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 1 }} checked={selected} />}
                {option.image && <Avatar alt={'-'} src={option.image} />}
                <Typography>{option.title ? option.title : option.name}</Typography>
              </Stack>
            </li>
          )}
          getOptionLabel={(option: Option) => {
            if (!option || !(option.title ? option.title : option.name) || (option.title ? option.title : option.name) === '') {
              return '';
            }
            var value = option.title ? option.title : option.name!;
            if (option.image && !multiple && value && value !== '') {
              return value + ';;' + option.image;
            }
            return value;
          }}
          options={options ?? []}
          loading={loading}
          onChange={(_, value, reason) => {
            if (onOptionChange) {
              onOptionChange(value);
            }
            field.onChange(multiple ? value : { ...value });
          }}
          renderInput={(params) => {
            const { value } = params.inputProps;
            var image = undefined;

            if (!multiple && typeof value === 'string' && value.includes(';;')) {
              const parsedValue = value.split(';;');
              image = parsedValue[1];
              params.inputProps = {
                ...params.inputProps,
                value: parsedValue[0],
              };
              params.InputProps = {
                ...params.InputProps,
                startAdornment: <Avatar src={image} alt=""/>
              }
            }
            return (
              <TextField
                {...params}
                {...other}
                error={!!error}
                helperText={error?.message ?? Object(error)?._id?.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            );
          }}
        />
      )}
    />
  );
}
