// scroll bar
import 'simplebar/src/simplebar.css';
import './utils/highlight';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import AdapterDateFnsTwo from '@mui/lab/AdapterDateFns';
import LocalizationProviderTwo from '@mui/lab/LocalizationProvider';
//
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import { createRoot } from 'react-dom/client';

// ----------------------------------------------------------------------

import './i18n';
// ReactDOM.render(
//   <HelmetProvider>
//     <CollapseDrawerProvider>
//       <BrowserRouter>
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//           <SettingsProvider>
//             <SnackAlertProvider>
//               <App />
//             </SnackAlertProvider>
//           </SettingsProvider>
//         </LocalizationProvider>
//       </BrowserRouter>
//     </CollapseDrawerProvider>
//   </HelmetProvider>,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <CollapseDrawerProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <LocalizationProviderTwo dateAdapter={AdapterDateFnsTwo}>
            <SettingsProvider>
              <App />
            </SettingsProvider>
          </LocalizationProviderTwo>
        </LocalizationProvider>
      </BrowserRouter>
    </CollapseDrawerProvider>
  </HelmetProvider>
);
