import { Amplify, Auth, Storage } from 'aws-amplify';
import Compressor from 'compressorjs';
import { sleepWithFunction } from '../utils';
import { AWS } from 'src/config';
import { FileTypes } from 'src/controllers/ImageController';
import { getFileType } from 'src/components/file-thumbnail';
import awsmobile from '../../aws-exports';

Amplify.configure(awsmobile);

Auth.configure({
  Auth: {
    identityPoolId: AWS.COGNITO_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: AWS.REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: AWS.USER_POOLS_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: AWS.USER_POOLS_WEB_CLIENT_ID,
  },
});

Storage.configure({
  region: AWS.REGION,
  bucket: AWS.BUCKET_NAME,
});

export const uploadFile = async (file: any, callBack: (link: string) => void) => {
  return new Promise((resolve) => {
    new Compressor(file, {
      quality: 0.6,
      success(result: File) {
        try {
          Storage.put(result.name, result, {
            contentType: result.type,
          }).then((success) => {
            callBack(success.key);
          });
        } catch (error) {
          console.log('Error uploading file: ', error);
        }
        resolve(true);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });
};

export const uploadFiles = async (files: File[], fileType: FileTypes) => {
  return Promise.all(
    files.map((file) => {
      if (getFileType(file.name, fileType) === FileTypes.IMAGE) {
        return new Promise<string>((resolve) => {
          new Compressor(file, {
            quality: 0.6,
            convertSize: Infinity,
            success(result: File) {
              try {
                Storage.put(result.name, result, {
                  contentType: result.type,
                }).then((success) => {
                  resolve(success.key);
                });
              } catch (error) {
                console.log('Error uploading file: ', error);
              }
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
      } else {
        return new Promise<string>((resolve) => {
          const result = [];
          try {
            Storage.put(file.name, file, {
              contentType: file.type,
            }).then((success) => {
              result.push(success.key);
              resolve(success.key);
            });
          } catch (error) {
            console.log('Error uploading file: ', error);
          }
        });
      }
    })
  );
};
