import { Languages } from './LocalesEnums';
import { getTimeWithAmPm } from 'src/models/utils/DateUtils';

export interface ILanguage {
  key: Languages;
  value?: string;
}

export class LocalesHelper {
  public static isEnglish() {
    const langStorage = localStorage.getItem('i18nextLng');

    switch (langStorage) {
      case Languages.ENGLISH:
        return true;
      case Languages.ARABIC:
        return false;
      default:
        return true;
    }
  }

  public static getLocale() {
    if (this.isEnglish()) {
      return Languages.ENGLISH;
    } else {
      return Languages.ARABIC;
    }
  }

  public static getLocalizedString(localizedString: ILanguage[]) {
    const language = this.getLocale();
    const defaultValue = localizedString[0].value;

    for (var i = 0; i < localizedString.length; i++) {
      const tuple = localizedString[i];

      if (tuple.key === language) {
        return tuple.value ? tuple.value : defaultValue;
      }
    }

    return defaultValue;
  }

}
