/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import GoogleDriveAuthButton from './GoogleDriveAuthButton';
import { RequestState, useApi, useSnackAlert } from 'identity-admin-ui';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

export default function VideoSection() {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [data, setData] = useState<{ fileId?: string; fileName?: string }>({});
  const { requestState: saveState, post: saveVide } = useApi();
  const { setSuccessAlert } = useSnackAlert();
  const navigate = useNavigate();

  const saveVideo = (formData: { name: string }) => {
    saveVide({
      url: 'projects',
      body: {
        videoPath: data.fileId,
        videoProvider: 'Google',
        fileName: data.fileName,
        name: formData.name,
      },
      onSuccess: (data) => {
        setSuccessAlert('Video Add successfully');
        navigate(`/projects/${data.record?.document?._id}/show`);
      },
    });
  };

  const methods = useForm({
    resolver: yupResolver(
      object({
        name: string().required('Please enter a project name').typeError('Please enter a project name'),
      })
    ),
    defaultValues: {
      name: '',
    },
  });
  const { handleSubmit } = methods;

  return (
    <Stack spacing={4}>
      {data.fileId && <GoogleDriveAuthButton onVideoSelected={setData} sx={{ alignSelf: 'end' }} />}
      {!data.fileId && (
        <Stack spacing={1} m={'auto'} justifyContent={'center'} alignItems={'center'}>
          <VideoLibraryIcon sx={{ fontSize: 120 }} color="secondary" />
          <Typography>Select file from your google drive:</Typography>
          <GoogleDriveAuthButton onVideoSelected={setData} />
        </Stack>
      )}

      {data.fileId && (
        <>
          <iframe
            ref={iframe}
            src={`https://drive.google.com/file/d/${data.fileId}/preview`}
            width="640"
            height="360"
            allow="autoplay"
            style={{ alignSelf: 'center' }}
          ></iframe>
          <FormProvider methods={methods} onSubmit={handleSubmit(saveVideo)}>
            <RHFTextField name="name" label="Project Name" size="small" sx={{ mb: 2 }} />
            {saveState.state === RequestState.FAILED && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {saveState.error?.meta?.message}
              </Alert>
            )}
            <LoadingButton
              sx={{ alignSelf: 'end' }}
              variant="contained"
              loading={saveState.state === RequestState.LOADING}
              type="submit"
            >
              Save
            </LoadingButton>
          </FormProvider>
        </>
      )}
    </Stack>
  );
}
