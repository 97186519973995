import { styled } from '@mui/material/styles';

import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
import { LoginForm } from '../../sections/auth';
import { LocalesHelper } from 'src/helpers/Localization/LocalesHelpers';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export enum UserTypes {
  ADMIN,
  VENDOR_USER,
  EVENT_CREATOR,
}

export default function Login(props: any) {
  const { userType } = props;

  const smUp = useResponsive('up', 'sm');
  const isEnglish: boolean = LocalesHelper.isEnglish()
  var headerWord = "Login to Cara Dashboard"


  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            
              
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Image
                  
                  src="/assets/illustrations/logo.jpeg"
                  alt="logo"
                  sx={{  width: '57px', mb: 3, objectFit: 'contain' }}
                />
                <Typography variant="h4" gutterBottom>
                  {headerWord}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                 {isEnglish? " Welcome back, Please enter your details below": "الرجاء إدخال البيانات الخاصة بك"}
                </Typography>
              </Box>
            </Stack>

            <LoginForm userType={userType} />

            
          </ContentStyle>
        </Container>
        {mdUp && (
          <Image
            visibleByDefault
            disabledEffect
            src="/assets/illustrations/logo.jpeg"
            alt="login"
            sx={{  width: '700px', objectFit: 'contain' }}
          />
        )}
      </RootStyle>
    </Page>
  );
}
