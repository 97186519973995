import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button, Typography } from '@mui/material';
// type
import { UploadMultiFileProps } from './type';
//
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';
import { LoadingButton } from '@mui/lab';
import BlankUploadScreen from './BlankUploadPage';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  border: `1px dashed white`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------
export interface IUploaderTexts {
  yourUploadedFiles?: string;
  removeAllButton?: string;
  uploadButton?: string;
  dropFilesToUpload?: string,
  orSelectPhotos?: string,
  maximumSize?: string
}
export interface UploadMultiFileContentProps extends UploadMultiFileProps {
  Content?: () => JSX.Element;
  isLoading?: boolean;
  uploaded?: boolean;
  texts?: IUploaderTexts;
}

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  uploaded,
  Content,
  isLoading,
  texts,
  ...other
}: UploadMultiFileContentProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });

  return (
    <Box sx={{ width: '100%', maxHeight: 440, ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'white',
            bgcolor: 'white',
          }),
        }}
      >
        <input {...getInputProps()} />

        {Content ? <Content /> : <BlankUploadScreen isBlank={files.length > 0 ? false : true} texts={texts}/>}

        {files.length > 0 && (
          <hr
            style={{
              color: 'black',
              marginTop: 20,
            }}
          />
        )}
      </DropZoneStyle>
      {files.length > 0 && (
        <Typography fontWeight={700} fontSize={15} marginLeft={1}>
          {texts?.yourUploadedFiles ?? 'Your uploaded files'}
        </Typography>
      )}
      <MultiFilePreview files={files} showPreview={showPreview} onRemove={onRemove} />

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {files.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            {texts?.removeAllButton ?? 'Remove all'}
          </Button>
          <LoadingButton size="small" variant="contained" onClick={onUpload} disabled={uploaded} loading={isLoading}>
            {texts?.uploadButton ?? 'Upload files'}
          </LoadingButton>
        </Stack>
      )}

      {helperText && helperText}
    </Box>
  );
}
