import { Controller, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';

// Define the validation schema
export const timeSchema = yup
  .date()
  .required('Time is required');

type IProps = {
  name: string;
};

type Props = IProps & TimePickerProps<Date>;

export default function RHFTimeInput({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          {...other}
          views={['minutes', 'seconds']}
          format="mm:ss"
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
              size: 'small',
            },
          }}
        />
      )}
    />
  );
}
