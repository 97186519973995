import { format, getTime, formatDistanceToNow } from 'date-fns';
import { Languages } from 'src/helpers/Localization/LocalesEnums';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDate2(date: Date | string | number | null) {
  return date ? format(new Date(date), 'MM/dd/yyyy') : '';
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd/MMM/yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function formatAMPM(date: Date, language: Languages) {
  var hours = date.getHours();
  var minutes: string | number = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';

  if (language === Languages.ARABIC) {
    ampm = localizingToArabicAMPM(ampm)
  } 

  hours = hours % 12;
  hours = hours ? hours : 12; 
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateReadable(date: Date | string | number | null) {
  return date ? format(new Date(date), 'dd MMMM yyyy') : '';
}

export function getIsoDate(date: Date | string, withTime: boolean, langauge?: Languages): string {

  date = new Date(date)
  const month = date.getMonth() + 1
  const day = date.getDate()
 
  const mappedMonth = addBegginingZero(month)
  const mappedDay = addBegginingZero(day)

  var isoDate = date.getFullYear() + "-" + mappedMonth + "-" + mappedDay
  
  if (withTime) {
    isoDate = isoDate + " " + formatAMPM(date, langauge? langauge: Languages.ENGLISH)
  }
  
  return isoDate
}

export function addBegginingZero (value: number): string {
  return value % 10 === value? `0${value.toString()}`: value.toString()
}

export function localizingToArabicAMPM (amOrPm: string) {

  switch (amOrPm) {
      
      case 'am':
          return 'صباحاً'
      
      case 'pm': 
          return 'مساءاً'

      default: 
          return 'صباحاً'
  }
}