import {  createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { AdminClient } from 'src/utils/axios/Axiosinterceptor';
import { DashBoardApiPaths } from 'src/controllers/ApiPaths';


const initialState: any = {
  isLoading: false,
  notifications: null
}

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
      saveNotifications(state, action) {
        state.notifications = action.payload;
      },
      clearNotifications(state) {
        state.notifications = null;
        state.isLoading = false
      }
    },
    extraReducers(builder) {
        builder
          .addCase(fetchNotifications.pending, (state, action) => {
            state.isLoading = true
          })
          .addCase(fetchNotifications.fulfilled, (state, action) => {
            state.isLoading = false
            state.notifications = action.payload
          })
          .addCase(fetchNotifications.rejected, (state, action) => {
            state.isLoading = false
            console.log(action.error)
          })
          
      }

});

export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (): Promise<any> => {
      
      const response = await AdminClient.get(DashBoardApiPaths.notifications);
      return response.data.data
    }
)


export const { saveNotifications, clearNotifications } = notificationSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getNotifications = (state: RootState) => ({
    notifications: state.notifications.notifications,
    isLoading: state.notifications.isLoading
 })

export default notificationSlice.reducer