/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack } from '@mui/material';
import AnnotationTable from './AnnotationTable';
import Page from 'src/components/Page';

export default function ShowCroppedVideo({ record }: any) {
  return (
    <Page title={record.record.name}>
      <Stack alignItems={'center'} spacing={2} overflow={'scroll'} width={'100%'}>
        <Stack direction={'row'} spacing={1}>
          <video src={record.record.croppedVideo} width={'auto'} height={360} controls={true} className="video-js" preload="auto" />
          <video src={record.record.originalVideo} width={'auto'} height={360} controls={true} className="video-js" preload="auto" />
        </Stack>
        <AnnotationTable annotations={record.record.annotations} />
      </Stack>
    </Page>
  );
}
