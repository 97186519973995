import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAdminData, LocalStorageKeys, removeUserDate, saveAdminData } from 'src/utils/axios/StorageManager';
import { AppDispatch, RootState, store } from 'src/store';
import LoginResponse from 'src/models/requests/LoginResponse';
import generateUUID from 'src/utils/UUIDGenerator';
import AuthSate from './AuthState';
import RequestState from 'src/models/requests/RequestState';
import { AdminClient } from 'src/utils/axios/Axiosinterceptor';
import { AdminApiPaths } from 'src/controllers/ApiPaths';

const initialState: AuthSate = {
  user: getAdminData(),
  status: RequestState.IDLE,
};

const adminAuthSlice = createSlice({
  name: 'adminAuth',
  initialState,
  reducers: {
    clearUserData: (state) => {
      removeUserDate(LocalStorageKeys.ADMIN);
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = RequestState.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        const user = { ...action.payload.user };
        const device = { ...action.payload.device };
        saveAdminData(user, device);
        state.status = RequestState.SUCCEEDED;
        state.user = user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = RequestState.FAILED;
        state.error = JSON.parse(action.error.message ?? '').meta;
      })
      .addCase(logout.fulfilled, (state) => {
        removeUserDate(LocalStorageKeys.ADMIN);
        state.user = null;
      })
      .addCase(logout.rejected, (state) => {
        removeUserDate(LocalStorageKeys.ADMIN);
        state.user = null;
      });
  },
});

export const login = createAsyncThunk(
  'dashboard/auth/login',
  async (credentials: { email: string; password: string; remember: boolean }): Promise<LoginResponse> => {
    const loginBody = {
      user: {
        ...credentials,
      },
      device: {
        deviceId: generateUUID(credentials.email),
        os: 'WEB',
      },
    };
    const response = await AdminClient.post(AdminApiPaths.login, loginBody, {
      _auth: false,
    });

    return response.data.data;
  }
);

export const logout = createAsyncThunk<void, { dispatch: AppDispatch }>(
  'dashboard/auth/logout',
  async ({ dispatch }): Promise<void> => {
    await AdminClient.delete(AdminApiPaths.logout, { _auth: false });

    return;
  }
);

export const { clearUserData } = adminAuthSlice.actions;

export const selectAdmin = (state: RootState) => state.auth.user;

export const selectRequestState = (state: RootState) => ({
  status: state.auth.status,
  error: state.auth.error,
});

export default adminAuthSlice.reducer;
