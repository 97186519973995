import { LoadingButton } from '@mui/lab';
import { useApi, useSnackAlert, RequestState } from 'identity-admin-ui';
import { useNavigate } from 'react-router';

export default function TrainDataAction(props: any) {
  const dataset = props.record?.record ?? props.record;
  const { requestState: datasetTrainingState, post: trainDataset } = useApi<{ dataurl?: string }>();
  const { setSuccessAlert } = useSnackAlert();
  const navigate = useNavigate();
  const trainData = () => {
    trainDataset({
      url: `datasets/${dataset._id}/train`,
      onSuccess(data) {
        setSuccessAlert('Data traind successfully');
        navigate(`/datasets/list`);
      },
    });
  };

  return (
    <LoadingButton
      variant="outlined"
      sx={{ alignSelf: 'end' }}
      onClick={() => trainData()}
      loading={datasetTrainingState.state === RequestState.LOADING}
    >
      Train
    </LoadingButton>
  );
}
