import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import { IconButtonAnimate } from '../../../components/animate';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { UserTypes } from 'src/pages/auth/Login';
import { logout as adminLogout, selectAdmin } from 'src/store/features/auth/AdminAuthSlice';
import MenuPopover from 'src/components/menu-popover';
import { logout as vendorUserLogout, selectVendorUser } from 'src/store/features/auth/VendorUserAuthSlice';
import { useNavigate } from 'react-router-dom';
import { useApi, usePaths } from 'identity-admin-ui';
import { useAuth } from 'src/hooks/useAuth';

type Props = {
  userType: UserTypes;
};

export default function AccountPopover({ userType }: Props) {
  const { user, logout: removeUserData } = useAuth();
  const navigate = useNavigate();
  const { root } = usePaths();
  const { remove: logout } = useApi();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logoutClicked = () => {
    logout({
      url: 'auth/logout',
      onSuccess(data) {
        removeUserData();
      },
    });
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={user?.photo} alt={user?.name} />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.username}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={logoutClicked}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
