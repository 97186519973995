/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import SplitedSegmentsTable from 'src/sections/videoSigment/SplitedSegmentsTable';
import RangeSelectionForm from 'src/sections/videoSigment/VideoRangeSelectionForm';

export default function ShowVideo(props: any) {
  const iframe = useRef<HTMLIFrameElement>(null);
  const { record } = props.record;
  const [showRangeOptions, setShowRangeOptions] = useState(false);
  console.log(iframe.current)
  return (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={3}>
      {record.videoPath && (
        <>
          <iframe
            ref={iframe}
            src={`https://drive.google.com/file/d/${record.videoPath}/preview`}
            width="640"
            height="360"
            allow="autoplay"
            style={{ alignSelf: 'center' }}
          ></iframe>
        </>
      )}
      {showRangeOptions && <RangeSelectionForm videoId={record.videoPath} _id={record._id} fileName={record.fileName} />}
      {!showRangeOptions && (
        <Button variant="contained" sx={{ alignSelf: 'end' }} onClick={() => setShowRangeOptions(true)}>
          Generate Segments
        </Button>
      )}
      {record.splitTimes && record.splitTimes.length > 0 && (
        <Card sx={{ width: '100%' }}>
          <CardHeader title="Generated Segments" />
          <CardContent>
            <SplitedSegmentsTable segments={record.splitTimes} />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
