enum RequestState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface IState<T> {
    data: T | undefined,
    state: RequestState,
    error?: any | null
}

export class State<T> {
  private constructor(public data: T | undefined, public state: RequestState, public error?: any | null) {}

  public static idle<T>(data?: T): IState<T> {
    const state = new State(data, RequestState.IDLE);
    return {
        ...state
    };
  }

  public static loading<T>(data?: T): IState<T> {
    const state = new State(data, RequestState.LOADING);
    return {
        ...state
    };
  }

  public static succeeded<T>(data: T): IState<T> {
    const state = new State(data, RequestState.SUCCEEDED);
    return {
        ...state
    };
  }

  public static error<T>(error: any, data?: T): IState<T> {
    const state = new State(data, RequestState.FAILED, error);
    return {
        ...state
    };
  }
}

export default RequestState;
