import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip } from '@mui/material';

interface Annotation {
  startFrame: number;
  endFrame: number;
  action: { name: string; color: string };
  score: number;
}

export default function AnnotationTable({ annotations }: { annotations: Annotation[] }) {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom component="div" style={{ padding: '16px' }}>
        Annotation
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start Frame</TableCell>
            <TableCell>End Frame</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {annotations.map((annotation, index) => (
            <TableRow key={index}>
              <TableCell>{annotation.startFrame}</TableCell>
              <TableCell>{annotation.endFrame}</TableCell>
              <TableCell>
                <Chip
                  label={annotation.action.name}
                  sx={{ color: 'black', border: 0,'&.MuiChip-outlined': { background: annotation.action.color } }}
                  variant="outlined"
                  size="medium"
                />
              </TableCell>
              <TableCell>{annotation.score ?? '---'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
