import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import { RequestState, useApi, useSnackAlert } from 'identity-admin-ui';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function AthleteTable({ athelets, segmentId }: any) {
  const navigate = useNavigate();
  const { requestState: exportVideoState, post: cropVideo } = useApi<{ video: any }>();
  const [loading, setLoading] = useState<string | null>(null);
  const { setSuccessAlert } = useSnackAlert();

  const handleGenerateTrainVideo = (athleteId: string) => runVideoCropper(athleteId, 'Train');

  const runVideoCropper = (athleteId: string, type: string) => {
    cropVideo({
      url: 'tasks',
      body: {
        segmentId,
        atheleteId: athleteId,
        type: type,
      },
      onSuccess(data) {
        setSuccessAlert('Video cropped successfully');
        navigate(`/tasks/list`);
      },
    });
    setLoading(`${athleteId}-${type}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Athlete ID</TableCell>
            <TableCell align="center">Create Task</TableCell>
            <TableCell align="center">Task status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(athelets)
            .sort((a, b) => +a - +b)
            .map((athlete: any) => (
              <TableRow key={athlete}>
                <TableCell align="center">{athlete}</TableCell>
                <TableCell align="center">
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => handleGenerateTrainVideo(athlete)}
                    loading={exportVideoState.state === RequestState.LOADING && loading === `${athlete}-Train`}
                  >
                    Create Task
                  </LoadingButton>
                </TableCell>
                <TableCell align="center">
                  {athelets[athlete].taskStatus ? <Chip label={athelets[athlete].taskStatus} /> : '---'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
