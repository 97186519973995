import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAdminData, getVendorUserData, LocalStorageKeys, removeUserDate, saveAdminData, saveVendorUserData } from 'src/utils/axios/StorageManager';
import {  AppDispatch, RootState, store } from 'src/store';
import LoginResponse from 'src/models/requests/LoginResponse';
import generateUUID from 'src/utils/UUIDGenerator';
import AuthSate from './AuthState';
import RequestState from 'src/models/requests/RequestState';
import { AdminClient } from 'src/utils/axios/Axiosinterceptor';
import { AdminApiPaths, VendorUserApiPaths } from 'src/controllers/ApiPaths';


const initialState: AuthSate = {
    user: getVendorUserData(),
    status: RequestState.IDLE,
}

const vendorUserAuthSlice = createSlice({
    name: 'vendorUserAuth',
    initialState,
    reducers: {
      clearVendorUserData: (state) =>  {
        removeUserDate(LocalStorageKeys.VENDOR_USER);
        state.user = null;
      }
    },
    extraReducers(builder) {
        builder
        .addCase(login.pending, (state, action) => {
          state.status = RequestState.LOADING;
        })
        .addCase(login.fulfilled, (state, action) => {
          const user = { ...action.payload.user };
          const device = { ...action.payload.device };
          saveVendorUserData(user, device);
          state.status = RequestState.SUCCEEDED;
          state.user = user;
        })
        .addCase(login.rejected, (state, action) => {
          state.status = RequestState.FAILED;
          state.error = JSON.parse(action.error.message ?? '').meta
        })
        .addCase(logout.fulfilled, (state) => {
          removeUserDate(LocalStorageKeys.VENDOR_USER);
          state.user = null;
        })
        .addCase(logout.rejected, (state) => {
          removeUserDate(LocalStorageKeys.VENDOR_USER);
          state.user = null;
        })
    },
});

export const login = createAsyncThunk(
    'vendorApp/auth/login',
    async (credentials: {email: string; password: string; remember: boolean;}): Promise<LoginResponse> => {
      const loginBody = {
        user: {
            ...credentials
        },
        device: {
            deviceId: generateUUID(credentials.email),
            os: 'WEB'
        }
      }
      const response = await AdminClient.post(VendorUserApiPaths.login, loginBody, {
        _auth: false
      });

      return response.data.data
    }
)

export const logout = createAsyncThunk<void, {dispatch: AppDispatch}>(
  'vendorApp/auth/logout',
  async ( { dispatch } ): Promise<void> => {
    
    await AdminClient.delete(VendorUserApiPaths.logout, { _auth: false });
    
    return;
  }
)


export const { clearVendorUserData } = vendorUserAuthSlice.actions;

export const selectVendorUser = (state: RootState) => state.vendorUserAuth.user;

export const selectVendorUserRequestState = (state: RootState) => ({
  status: state.vendorUserAuth.status,
  error: state.vendorUserAuth.error
});


export default vendorUserAuthSlice.reducer;

