import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip } from '@mui/material';
import { formatTime } from 'src/utils/utils';

interface TimeLog {
  split: number;
  detect: number;
  track: number;
}

export default function TimeLogTable({ log }: { log: TimeLog }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Split Time</TableCell>
            <TableCell>Detect Time</TableCell>
            <TableCell>Track Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{log.split ? formatTime(log.split) : '---'}</TableCell>
            <TableCell>{log.detect ? formatTime(log.detect) : '---'}</TableCell>
            <TableCell>{log.track ? formatTime(log.track) : '---'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
