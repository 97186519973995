import Device from "src/models/entries/Device";
import User from "src/models/entries/User";

export enum LocalStorageKeys {
   ADMIN = 'ADMIN',
   VENDOR_USER = "VENDOR_USER"
}


export const getAccessToken = (key: LocalStorageKeys): string | null => getUserData(key)?.accessToken ?? null;

export const getRefershToken = (key: LocalStorageKeys): string | null => getUserData(key)?.refreshToken ?? null;

export const updateTokens = (accessToken: string, refreshToken: string, key: LocalStorageKeys) => {
    const user = getUserData(key);   
    if(!user) {
        return;
    }
    saveUser(user, key);
}

export const saveAdminData = (user: any, device: any) => {
    saveUser(user, LocalStorageKeys.ADMIN);
}

export const saveVendorUserData = (user: any, device: any) => {
    saveUser(user, LocalStorageKeys.VENDOR_USER);
}



export const saveUser = (user: User, key: LocalStorageKeys) => {
    const userData = JSON.stringify(user);
    localStorage.setItem(key, userData);
}


export const getAdminData = (): User | null => getUserData(LocalStorageKeys.ADMIN);
export const getVendorUserData = (): User | null => getUserData(LocalStorageKeys.VENDOR_USER);

export const getUserData = (key: LocalStorageKeys) : User | null => {
    const userData = localStorage.getItem(key)
    if (!userData) {
        return null;
    }
    try{
        const user = JSON.parse(userData);
        if (!user) {
            return null;
        }
        return user;
    }catch(error) {
        return null
    }
}

export const removeUserDate = (key: LocalStorageKeys) => {
    localStorage.removeItem(key)
}