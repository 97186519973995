import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { Button, IconButton, Stack } from '@mui/material';
import { RequestState, useApi, useSnackAlert } from 'identity-admin-ui';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { FormProvider } from 'src/components/hook-form';
import RHFTimeInput, { timeSchema } from 'src/components/hook-form/RHFTimeInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, date, object } from 'yup';

type Inputs = {
  startFrame?: Date;
  endFrame?: Date;
};

function getTimeInSeconds(time: Date) {
  const minutesString = `${new Date(time).getMinutes()}`;
  const secondsString = `${new Date(time).getSeconds()}`;
  const formattedTime = `${minutesString.padStart(minutesString.length, '0')}:${secondsString.padStart(
    secondsString.length,
    '0'
  )}`;
  const timeParts = formattedTime.split(':');
  let minutes = '0';
  let seconds = '0';

  if (timeParts.length === 2) {
    minutes = timeParts[0].slice(0, 2);
    seconds = timeParts[1].slice(0, 2);
  }
  return +minutes * 60 + +seconds;
}

export default function RangeSelectionForm({ videoId, _id, fileName }: any) {
  const { requestState: createSegmentsStates, post: createSegments } = useApi();
  const { setSuccessAlert } = useSnackAlert();
  const navigate = useNavigate();
  const methods = useForm<{ frameRanges: Inputs[] }>({
    resolver: yupResolver(
      object({
        frameRanges: array().of(
          object({
            startFrame: date()
              .required('Start time is required')
              .test('is-before-end', 'Start time must be before end time', function (value) {
                const { endFrame } = this.parent;
                return value && endFrame ? new Date(value) < new Date(endFrame) : true;
              }),
            endFrame: date()
              .required('End time is required')
              .test('is-after-start', 'End time must be after start time', function (value) {
                const { startFrame } = this.parent;
                return startFrame && value ? new Date(startFrame) < new Date(value) : true;
              }),
          })
        ),
      })
    ),
    defaultValues: {
      frameRanges: [
        {
          startFrame: undefined,
          endFrame: undefined,
        },
      ],
    },
  });
  const { control, handleSubmit, getFieldState, getValues } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'frameRanges',
  });
  const onSubmit: SubmitHandler<{ frameRanges: Inputs[] }> = (data) => {
    createSegments({
      url: 'videoSegments/all',
      body: {
        frameRanges: data.frameRanges.map((range) => ({
          startFrame: getTimeInSeconds(range.startFrame ?? new Date()),
          endFrame: getTimeInSeconds(range.endFrame ?? new Date()),
        })),
        fileId: videoId,
        videoId: _id,
        name: fileName ?? new Date().getTime(),
      },
      onSuccess: () => {
        setSuccessAlert('Video splitted successfully');
        navigate('/videoSegments/list');
      },
    });
  };

  const removeRange = (index: number) => {
    remove(index);
  };
  const addRange = () => {
    append({
      startFrame: undefined,
      endFrame: undefined,
    });
  };

  return (
    <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
      <Stack spacing={4} justifyContent="center" alignItems="center">
        <Stack id="frameRanges" spacing={2}>
          {fields.map((field: any, index: number) => (
            <Stack key={field.id} spacing={2} direction="row" alignItems="center" justifyContent="center">
              <RHFTimeInput name={`frameRanges.${index}.startFrame`} label="Start Time" />
              <RHFTimeInput name={`frameRanges.${index}.endFrame`} label="End Time" />

              {fields.length > 1 && (
                <IconButton onClick={() => removeRange(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 text-red-600"
                    width={24}
                    height={24}
                    color="red"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>
        <Button
          id="addRange"
          onClick={addRange}
          variant="outlined"
          sx={{
            alignSelf: 'start',
          }}
          startIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              width={24}
              height={24}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          }
        >
          Add Range
        </Button>
        <LoadingButton
          type="submit"
          sx={{ alignSelf: 'end' }}
          variant="contained"
          loading={createSegmentsStates.state === RequestState.LOADING}
        >
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
