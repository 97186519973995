import { AxiosRequestTransformer } from "axios"
import { fDate } from "../formatTime";

export const dateTransformer: AxiosRequestTransformer = data => {
    if (data instanceof Date) {
      return fDate(data, 'yyyy-MM-dd');
    }
    if (Array.isArray(data)) {
      return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
      return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
        [ key, dateTransformer(val) ]))
    }
    return data
}