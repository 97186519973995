import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import { useAppSelector } from 'src/store/hooks';
import { selectAdmin } from 'src/store/features/auth/AdminAuthSlice';
import AdminRouter from './AdminRouter';
import { selectVendorUser } from 'src/store/features/auth/VendorUserAuthSlice';
import { sizeType } from 'src/helpers/Types';
import { usePaths, useResources } from 'identity-admin-ui';
import { ADMIN_PATH, VendorUser_PATH } from './paths';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export var SIZE: sizeType = 'medium';

export default function Router() {
  const { pathname } = useLocation();
  const isVendorUser = pathname.includes('/vendorApp');

  const { resources } = useResources();
  const adminState = useAppSelector(isVendorUser ? selectVendorUser : selectAdmin);

  const userPath = isVendorUser ? VendorUser_PATH : ADMIN_PATH;

  return useRoutes([
    AdminRouter(),
    {
      path: '*',
      element: adminState ? resources ? <NotFound /> : <LoadingScreen /> : <Navigate to={userPath.auth.login} />,
    },
  ]);
}

// Dashboard
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
