import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, Link } from '@mui/material';
import { formatTime } from 'src/utils/utils';

// Helper function to format seconds to mm:ss


type Props = {
  segments: { time: number; segmentsIds: { _id: string; segmentPath: string, name: string }[] }[];
};

export default function SplitedSegmentsTable({ segments }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Time (mm:ss)</TableCell>
            <TableCell>Video Segments</TableCell>
            <TableCell>Download Links</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {segments.map((row, index) => (
            <TableRow key={index} sx={{ borderBottom: '1px solid lightGray' }}>
              <TableCell>{formatTime(row.time)}</TableCell>
              <TableCell>
                <Stack direction="column" spacing={1}>
                  {row.segmentsIds.map((link, linkIndex) => (
                    <Link href={`/videoSegments/${link._id}/show`} key={link._id}>
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="column" spacing={1}>
                  {row.segmentsIds.map((link, linkIndex) => (
                    <Link href={link.segmentPath} key={link._id}>
                      {link.segmentPath.split('/')[link.segmentPath.split('/').length - 1]}
                    </Link>
                  ))}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
