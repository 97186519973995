// ----------------------------------------------------------------------

export function path(root: string, sublink: string) {
    return `${root}${sublink}`;
  }
  
  export const ADMIN_ROOT = ''
  export const VendorUser_ROOT = '/vendorApp'

  export var ADMIN_PATH: {[key:string]: any} = {
    root: ADMIN_ROOT,
    auth: {
      login: path(ADMIN_ROOT, '/login')
    },  

  }

  export var VendorUser_PATH: {[key:string]: any} = {
    root: VendorUser_ROOT,
    auth: {
      login: path(VendorUser_ROOT, '/login')
    },  

  }



  
  