import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const CopyButton = ({ textToCopy }: {textToCopy: string}) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if(copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  },[copied])
  return (
    <div>
      <CopyToClipboard text={textToCopy} onCopy={() => setCopied(true)}>
        <IconButton><ContentCopyRoundedIcon /> </IconButton>
      </CopyToClipboard>
      {copied && <span style={{ color: 'green' }}>Copied!</span>}
    </div>
  );
};

export default CopyButton;