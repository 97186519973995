/* eslint-disable react/jsx-no-bind */
import { Button, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Google as GoogleIcon } from '@mui/icons-material';

let google: any;
export let gapi: any;
const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
const DISCOVERY_DOCS = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

export default function GoogleDriveAuthButton({ onVideoSelected, sx }: any) {
  const [tokenClient, setTokenClient] = useState<{
    tokenClient: any;
    gapiInited: boolean;
    gisInited: boolean;
    accessToken: string | null;
  }>({ tokenClient: null, gapiInited: false, gisInited: false, accessToken: '' });

  useEffect(() => {
    const initializeGapiClient = async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOCS],
      });
      setTokenClient((prev) => ({ ...prev, gapiInited: true }));
    };

    const gapiLoaded = () => {
      gapi = (window as any).gapi;
      gapi.load('client', initializeGapiClient);
      gapi.load('client:picker', initializePicker);
    };
    async function initializePicker() {
      await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
      setTokenClient((prev) => ({ ...prev, gapiInited: true }));
    }
    const gisLoaded = () => {
      google = (window as any).google;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      setTokenClient((prev) => ({ ...prev, tokenClient, gisInited: true }));
    };

    const loadGapiLibrary = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = gapiLoaded;
      document.body.appendChild(script);
    };

    const loadGisLibrary = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = gisLoaded;
      document.body.appendChild(script);
    };

    loadGapiLibrary();
    loadGisLibrary();
  }, []);

  const createPicker = useMemo(
    () => (access_token: string) => {
      const view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes(
        'video/mp4, video/mpeg, video/ogg, video/webm, video/3gpp, video/x-msvideo, video/quicktime, video/x-ms-wmv, video/x-flv'
      );
      const picker = new google.picker.PickerBuilder()
        .setDeveloperKey(API_KEY)
        .setAppId(APP_ID)
        .setOAuthToken(access_token)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    },
    [pickerCallback]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function pickerCallback(data: any) {
    if (data.action === google.picker.Action.PICKED) {
      const document = data[google.picker.Response.DOCUMENTS][0];
      const fileId = document[google.picker.Document.ID];
      const fileName = document[google.picker.Document.NAME];
      onVideoSelected({ fileId, fileName });
    }
  }

  function handleAuthClick() {
    if (tokenClient.gapiInited && tokenClient.gisInited && tokenClient.tokenClient) {
      tokenClient.tokenClient.callback = async (resp: any) => {
        if (resp.error !== undefined) {
          throw resp;
        }
        // await listFiles();
        setTokenClient((prev) => ({ ...prev, accessToken: resp.access_token }));
        createPicker(resp.access_token);
      };

      if (tokenClient.accessToken === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.tokenClient.requestAccessToken({ prompt: '' });
      }
    }
  }

  return (
    <Button onClick={handleAuthClick} variant="contained" startIcon={<GoogleIcon />} sx={sx}>
      Select From Google Drive
    </Button>
  );
}
