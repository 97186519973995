const stringToUUID = (str: string) => {
    str = str.replace('-', '');
    return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function(c, p) {
      return str[p % str.length];
    });
}

const generateUUID = (email: string): string => {
    const string = (navigator.vendor + email).split("").map(char => char.charCodeAt(0)).join('')
    return stringToUUID(string);
}

export default generateUUID;