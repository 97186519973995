import { IdenityModelConfiguration, IdentityEdit, IdentityPermissionPage, IdentityShow } from 'identity-admin-ui';
import ShowSegmentVideo from 'src/pages/ShowSegmentVideo';
import ShowVideo from 'src/pages/ShowVideo';
import EditCroppedVideo from 'src/sections/croppedVideo/EditCroppedVideo';
import ShowCroppedVideo from 'src/sections/croppedVideo/ShowCroppedVideo';
import TaskActions from 'src/sections/croppedVideo/TaskActions';
import TrainDataAction from 'src/sections/dataset/TrainDataAction';
import VideoSegmentActions from 'src/sections/videoSigment/Actions';
import VideoSection from 'src/sections/videoSigment/VideoSection';

const video = {
  path: 'projects',
  children: [
    {
      path: 'new',
      element: <IdentityEdit modelRoute="projects" path="project" isEdit={false} Component={() => <VideoSection />} />,
    },
    {
      path: ':id/show/',
      element: <IdentityShow modelRoute="projects" path="project" Component={ShowVideo} />,
    },
  ],
};

const videoSegment = {
  path: 'videoSegments',
  children: [
    {
      path: ':id/show/',
      element: (
        <IdentityShow modelRoute="videoSegments" path="videoSegment" Component={ShowSegmentVideo} Actions={VideoSegmentActions} />
      ),
    },
  ],
};

const croppedVideos = {
  path: 'tasks',
  children: [
    {
      path: ':id/show/',
      element: <IdentityShow modelRoute="tasks" path="task" Component={ShowCroppedVideo} Actions={TaskActions}/>,
    },
    {
      path: ':id/edit/',
      element: <IdentityEdit modelRoute="tasks" path="task" Component={EditCroppedVideo} isEdit={true} Actions={TaskActions}/>,
    },
  ],
};

const modelConfiguration = {
  path: 'modelConfigurations',
  children: [
    {
      path: ':id/edit',
      element: <IdenityModelConfiguration path={'modelConfiguration'} modelRoute={'modelConfigurations'} />,
    },
  ],
};

const permissionGroup = {
  path: 'permissionGroups',
  children: [
    {
      path: 'new',
      element: (
        <IdentityEdit
          key={`.permissionGroups${Math.random()}.new`}
          isEdit={false}
          Component={() => <IdentityPermissionPage isEdit={false} />}
          modelRoute="permissionGroups"
          path="permissionGroup"
        />
      ),
    },
    {
      path: ':id/edit',
      element: (
        <IdentityEdit
          key={`.permissionGroups${Math.random()}.edit`}
          isEdit={true}
          Component={() => <IdentityPermissionPage isEdit={true} />}
          modelRoute="permissionGroups"
          path="permissionGroup"
        />
      ),
    },
  ],
};

const dataset = {
  path: 'datasets',
  children: [
    {
      path: ':id/show/',
      element: <IdentityShow modelRoute="datasets" path="dataset"  Actions={TrainDataAction}/>,
    }
  ],
};


const customs = [video, videoSegment, croppedVideos, modelConfiguration, permissionGroup, dataset];

export default customs;
