export const adminPath = 'admin';
export const vendorUserPath = 'vendorUser';

const dashboardRoot = `${adminPath}/resources`;
const vendorUserRoot = `${vendorUserPath}/resources`;

export const AdminApiPaths = {
    extendToken: `${adminPath}/auth/extendToken`,
    login: `${adminPath}/auth/login`,
    logout: `${adminPath}/auth/logout`,
}

export const DashBoardApiPaths = {
    resources: `${adminPath}/resources`,
    notifications: `${adminPath}/notifications`,
    executeActions: `${adminPath}/actions/execute`,
    actionVisible: `${adminPath}/actions/checkVisible`
}

export const DashboardApiPaths = {
    resource: (modelName: string) => `${dashboardRoot}/${modelName}`, 
}

export const VendorUserApiPaths = {
    extendToken: `${vendorUserPath}/auth/extendToken`,
    login: `${vendorUserPath}/auth/login`,
    logout: `${vendorUserPath}/auth/logout`,
}

export const DashBoardVendorUserApiPaths = {
    resources: `${vendorUserPath}/resources`,
    executeActions: `${vendorUserPath}/actions/execute`,
    actionVisible: `${vendorUserPath}/actions/checkVisible`
}

export const DashboardVendorUserApiPaths = {
    resource: (modelName: string) => `${vendorUserRoot}/${modelName}`, 
}

export const ChatApiPaths = {
    getChatUser: (name: string) => `${adminPath}/chatUsers/getByName?name=${name}`,
    getConversations: `${adminPath}/chatMessages/conversations`,
    getConversation: (conversationId: string) => `${adminPath}/chatMessages/conversations/${conversationId}`,
    getParticipant: (chatUserId: string) => `${adminPath}/chatUsers/getParticipant/${chatUserId}`,
    readConversation: (chatUserId: string) => `${adminPath}/chatUsers/readMessage/${chatUserId}`,
    sendMessage: (chatUserId: string) => `${adminPath}/chatUsers/sendMessage/${chatUserId}`,
    sendImageMessage: (chatUserId: string) => `${adminPath}/chatUsers/sendImageMessage/${chatUserId}`,
    getMedia: (mediaId: string) => `${adminPath}/chatMessages/media/${mediaId}`,
}