import { Paper, PaperProps, Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '', ...other }: Props) {
  const { translate } = useLocales();
  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {translate("general.noResults")}
      </Typography>
      <Typography variant="body2" align="center">
        {translate("general.noResultsFound")} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. {translate("general.tryCheckingTypos")}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2"> Please enter keywords</Typography>
  );
}
