import { Box, BoxProps, SvgIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// ----------------------------------------------------------------------

//import * as Icons from '@mui/icons-material'

interface Props extends BoxProps {
  src: string;
}
const Icons: { [key: string]: any } = require('@mui/icons-material');

export default function SvgIconStyle({ src, sx }: Props) {
  const defualtStyle = (
    <Box
      component="span"
      sx={{
        width: 24,
        height: 24,
        display: 'inline-block',
        bgcolor: 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
    />
  );
  const customStyle = <Box sx={{ display: 'flex', gap: 1 }}>
  <SvgIcon component={Icons[src]} color="inherit" />
    </Box>

      const isCustom = Icons[src] !== undefined;

  return  isCustom ? customStyle : defualtStyle;
}
