import { LoadingButton } from '@mui/lab';
import { useApi, RequestState } from 'identity-admin-ui';

export default function VideoSegmentActions(props: any) {
  const videoSegment = props.record?.record;
  const { requestState: athelteTrackingState, patch: runTracker } = useApi<{ trackedPath?: string }>();

  if (videoSegment?.isTracked) {
    return <></>;
  }

  const trackAthletes = () => {
    runTracker({
      url: `videoSegments/${videoSegment._id}/track`,
      body: {},
      onSuccess(data) {
        window.location.reload();
      },
    });
  };

  return (
    <LoadingButton
      variant="outlined"
      sx={{ alignSelf: 'end' }}
      onClick={trackAthletes}
      loading={athelteTrackingState.state === RequestState.LOADING}
    >
      Run Tracker
    </LoadingButton>
  );
}

// {!record.detectedPath && (
//     <LoadingButton
//       variant="outlined"
//       sx={{ alignSelf: 'end' }}
//       onClick={detectAthletes}
//       loading={athelteDetectionState.state === RequestState.LOADING}
//     >
//       Run Athletes Detector
//     </LoadingButton>
//   )}
