// AuthReducer.ts

import { Reducer } from 'react';
import User from 'src/models/entries/User';

export enum ActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}


export interface State {
  user: User | null;
  isAuthenticated: boolean;
}

export type Action =
  | { type: ActionTypes.LOGIN; payload: User }
  | { type: ActionTypes.LOGOUT };

const AuthReducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
