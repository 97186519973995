export enum ArabicNotification {
    notifications = "إشعارات",
    youHave = "لديك",
    unreadMessages = "رسائل غير مقروءة",
    showMore = "عرض المزيد",
    showLess = "عرض أقل"
}

export enum EnglishNotification {
    notifications = "Notifications",
    youHave = "You have",
    unreadMessages = "unread messages",
    showMore = "Show more",
    showLess = "Show less"
}